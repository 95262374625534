body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
  color: "#212121";
}

@font-face {
  font-family: "Fugue";
  src: local("Fugue");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-select {
  user-select: none;
}

.no-select::selection {
   background: transparent; 
}

.no-select::-moz-selection { 
  background: transparent; 
}

input {
  font-family: "Work Sans";
  background-color: transparent;
}

@keyframes modal-appear {
  from {transform: translateY(100vh) scale3d(0.2,0.2,0.2); opacity: 0}
  to {transform: translateY(0vh) scale3d(1,1,1); opacity: 1}
}

.modal {
  animation-name: modal-appear;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #383737;
    color: #ffffff;
  }
}